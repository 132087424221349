import { red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

// A custom theme for this app
let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      // Add custom breakpoints
      custom1150: 1150,
      custom960: 960,
    },
  },
  palette: {
    primary: {
      main: "#B90605",
      dark: "#990200",
      light: "#ce2120",
    },
    secondary: {
      main: "#EBEAEA",
      dark: "#D0CECE",
      black: "#130D1F",
    },
    button: {
      orange: "#E35F30",
      orangeLight: "#e47751",
      yellow: "#fbb802",
      yellowLight: "#ffce47",
    },
    error: {
      main: red.A400,
      light: red.A100,
    },
    background: {
      default: "#fdfdfd",
      paper: "#fdfdfd",
    },
    text: {
      primary: "#333",
      secondary: "#ffffff",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: "none",
        },
      },
    },
  },
  typography: {
    fontFamily: "Fira Sans",
    h1: {
      fontSize: "3.203272rem",
      fontWeight: 600,
    },
    h2: {
      fontSize: "2.4883125rem",
    },
    h3: {
      fontSize: "2.073625rem",
    },
    h4: {
      fontSize: "1.728rem",
    },
    h5: {
      fontSize: "1.4375rem",
    },
    h6: {
      fontSize: "1.2512rem",
    },
    body1: {
      fontSize: "1.1875rem",
    },
    body2: {
      fontSize: "1rem",
    },
    button: {
      padding: "10px 13px",
      borderRadius: "0px",
      fontWeight: 400,
      fontSize: "0.875",
      textAlign: "center",
      textTransform: "uppercase",
      textDecoration: "none",
      minWidth: "7rem",
    },
  },
});

export default theme;
